import { Link } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";

function Profile() {

  return (
    <section class="vh-100">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col col-xl-10">
            <div class="card">
              <div class="row g-0">
                <div class="col-md-6 col-lg-5 d-none d-md-block">
                  <img src="https://images.unsplash.com/photo-1578615437406-511cafe4a5c7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=654&q=80"
                    alt="login form" class="img-fluid" />
                </div>
                <div class="col-md-6 col-lg-7 d-flex align-items-center">
                  <div class="card-body p-4 p-lg-5 text-black">
                    <form>
                      <div class="d-flex align-items-center mb-3 pb-1">
                        <i class="fas fa-cubes fa-2x me-3"></i>
                        <span class="h1 fw-bold mb-0">Splash Page</span>
                      </div>
                      <div class="pt-1 mb-4">
                        <Link to="/LoginForm"><button class="btn btn-dark btn-lg btn-block" type="button">Login</button></Link>
                      </div>
                      <div class="pt-1 mb-4">
                        <Link to="/NewAccount"><button class="btn btn-dark btn-lg btn-block" type="button">Register Account</button></Link>
                      </div>
                      <a class="small text-muted" href="#!">Forgot password?</a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Profile;
