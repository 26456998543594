import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";



function NewAccount() {

    return (
        <section class="vh-100">
            <div class="container py-5 h-100">
                <div class="row d-flex justify-content-center align-items-center h-100">
                    <div class="col col-xl-10">
                        <div class="card">
                            <div class="row g-0">
                                <div class="col-md-6 col-lg-5 d-none d-md-block">
                                    <img src="https://images.unsplash.com/photo-1536697246787-1f7ae568d89a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                                        alt="login form" class="img-fluid" />
                                </div>
                                <div class="col-md-6 col-lg-7 d-flex align-items-center">
                                    <div class="card-body p-4 p-lg-5 text-black">
                                        <form class="row g-3">
                                            <div class="d-flex align-items-center mb-3 pb-1">
                                                <i class="fas fa-cubes fa-2x me-3"></i>
                                                <span class="h1 fw-bold mb-0">Create an Account</span>
                                            </div>

                                            <div class="col-auto from-outline mb-4">
                                                <input type="password" class="form-control form-control-md" id="inputPassword2" placeholder="First Name" />
                                            </div>
                                            <div class="col-auto">
                                                <input type="password" class="form-control form-control-md" id="inputPassword2" placeholder="Last Name" />
                                            </div>

                                            <div class="form-outline mb-4">
                                                <input type="password" class="form-control form-control-md" id="inputPassword2" placeholder="Email Address" />
                                            </div>

                                            <div class="col-auto from-outline mb-4">
                                                <input type="password" class="form-control form-control-md" id="inputPassword2" placeholder="Password" />
                                            </div>
                                            <div class="col-auto">
                                                <input type="password" class="form-control form-control-md" id="inputPassword2" placeholder="Repeat Password" />
                                            </div>

                                            <div class="pt-1 mb-4">
                                                <button class="btn btn-dark btn-lg btn-block" type="button">Create</button>
                                            </div>
                                            <p class="mb-5 pb-lg-2">Have an account? <a>
                                                <Link to="/LoginForm">Login Here</Link>
                                            </a></p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewAccount