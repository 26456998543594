import React, { useState } from "react";
import "./index.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginForm from "./views/LoginForm";
import Profile from "./views/Profile"
import NewAccount from "./views/NewAccount"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';



function App() {
  const adminUser = {
    email: "admin@admin.com",
    password: "admin123"
  }

  const [user, setUser] = useState({ name: "", email: "" });
  const [error, setError] = useState("");

  const Login = details => {
    console.log(details);
    if (details.email === adminUser.email && details.password === adminUser.password) {
      console.log("Logged In")
      setUser({
        name: details.name,
        email: details.email
      })
    } else {
      console.log("Details do not match!");
      setError("Details do not match!");
    }
  }

  const Logout = () => {
    console.log("Logout")
    setUser({ name: "", email: "" });
  }

  return (
    <>
      <Router>
        <div className="App">
          <Routes>
          <Route path="/" element={<Profile />} />
            <Route path="/LoginForm" element={<LoginForm />} />
            <Route path="/NewAccount" element={<NewAccount />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
